import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

const ISRAELI_FAKE_GOVERNMENTAL_ID = "123789456"
const UAE_FAKE_GOVERNMENTAL_ID_SUFFIX = "12345678"

export const fixPhone = (phoneNumber, defaultLocale) => {
  if (!phoneNumber || !defaultLocale) {
    return null
  }
  const phone = phoneUtil.parse(phoneNumber, defaultLocale)
  return phoneUtil.format(phone, PhoneNumberFormat.E164)
}

export const isPhoneValid = (phoneNumber, defaultLocale) => {
  if (!phoneNumber || !defaultLocale) {
    return false
  }
  try {
    const phone = phoneUtil.parse(phoneNumber, defaultLocale)
    const countryCode = phone.getCountryCode()
    const isMobile = phoneUtil.getNumberType(phone) === PhoneNumberType.MOBILE
    const isCountryCoded = phoneNumber.startsWith(countryCode) || phoneNumber.startsWith(`+${countryCode}`)
    if (!isMobile && !isCountryCoded) {
      return false
    }
    return phoneUtil.isValidNumber(phone)
  } catch (e) {
    return false
  }
}

const validateIsraeliGovernmentalId = (governmentalId) => {
  if (!/^\d+$/.test(governmentalId) || governmentalId.length !== 9 || parseInt(governmentalId) === 0) {
    return false
  }
  if (governmentalId === ISRAELI_FAKE_GOVERNMENTAL_ID) {
    return true
  }
  return verifyChecksumNumber(governmentalId)
}

const validateUaeGovernmentalId = (governmentalId, birthYear) => {
  if (!/^\d+$/.test(governmentalId) || governmentalId.length !== 15 || parseInt(governmentalId) === 0) {
    return false
  }
  if (governmentalId.slice(0, 3) !== "784") {
    return false
  }
  if (governmentalId.slice(3, 7) !== birthYear) {
    return false
  }
  if (governmentalId.slice(7) === UAE_FAKE_GOVERNMENTAL_ID_SUFFIX) {
    return true
  }
  return verifyChecksumNumber(governmentalId)
}

const verifyChecksumNumber = (governmentalId) => {
  let sum = 0
  for (let i = 0; i < governmentalId.length; i++) {
    const digit = parseInt(governmentalId[i])
    let idDigitCounter = i % 2 === 0 ? digit : digit * 2
    if (idDigitCounter > 9) {
      idDigitCounter = Math.floor(idDigitCounter / 10) + (idDigitCounter % 10)
    }
    sum += idDigitCounter
  }
  return sum % 10 === 0
}

export const validateGovernmentalId = (governmentalIdType, governmentalId, birthYear) => {
  if (!governmentalId) {
    return false
  }
  if (governmentalIdType === "Israel") {
    return validateIsraeliGovernmentalId(governmentalId)
  }
  if (governmentalIdType === "UAE") {
    return validateUaeGovernmentalId(governmentalId, birthYear)
  }
  return true
}
